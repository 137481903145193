import React, { Component } from 'react';
import { graphql, Link } from "gatsby"
// import { Link } from "gatsby"
import SEO from '../components/SEO'
import { Textes, Images, DonneesTechniques, RealisationPrixEtCollaborateurs, Detail } from "../modules";

class PageRealisation extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    
  }

  componentDidMount(){
    const details = document.querySelectorAll(".detail .index")
    details.forEach((el, i) => {
      // console.log(i,el)
      el.innerHTML = "0"+(i+1)
    })
  }

  _renderModules(modules) {
    // console.log(modules)
    const module = modules.map((module, i) => {
      // console.log(module.__typename)
      switch (module.__typename) {
        case "ContentfulRealisationDescription":
          return <Textes key={i} input={module} />;
        case "ContentfulRealisationImage":
          return <Images key={i} input={module} />;
        // case "ContentfulRealisationDetail":
        //   return <Detail key={i} input={module} />;
        case "ContentfulRealisationDonneesTechniques":
          return <DonneesTechniques key={i} input={module} />;
        case "ContentfulRealisationPrixEtCollaborateurs":
          return <RealisationPrixEtCollaborateurs key={i} input={module} />;
        default:
          return null;
      }
    });
    return module;
  }

  _renderDetails(modules){
    const module = modules.map((module, i) => {
      // console.log(module.__typename)
      switch (module.__typename) {
        case "ContentfulRealisationDetail":
          return <Detail key={i} input={module} />;
        default:
          return null;
      }
    });
    return module;
  }

  render() {
    // console.log(this.props)
    const { data } = this.props
    const {
      titre,
      seo_partage,
      modules
    } = data.realisation
    const { related } = data
    // console.log(data)
    return (
      <div className="realisation">
        <SEO
          page_title={seo_partage.page_title}
          meta_description={seo_partage.meta_description.childMarkdownRemark.rawMarkdownBody}
          poster={seo_partage.poster_image.file.url}
          page={true}
          bodyClass="template-realisation"
          node={data.realisation}
          /> 
        <div>
          <div className="header">
            <h1 className="crtch">{titre}</h1>
          </div>

          
          <div className="content">
            <div className="row">
              {this._renderModules(modules)}
            </div>
            <section className="module detail">
              <div className="row">
                {this._renderDetails(modules)}
              </div>
            </section>
          </div>
        </div>
        <div className="footer">
          <ul className="related">
            {related.edges.map( ({node}, i) => (
              <li className="" key={i}>
                <Link to={'/realisation/'+node.slug} className="list-item crtch">
                  <h2>{node.titre}</h2>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default PageRealisation;

export const pageQuery = graphql `
  query RealisationBySlug($slug: String!) {
    realisation: contentfulRealisation(slug: { eq: $slug }) {
      ...realisation
    }
    related: allContentfulRealisation(filter: {slug: {ne: $slug}}) {
      edges {
        node {
          ...realisation
        }
      }
    }
  }
`