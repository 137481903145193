import React from "react";
import TexteLexique from '../components/ui/TexteLexique'

const Textes = ({ input }) => {
  // console.log(input)
  return (
    <section className="module textes col-md-8 col-sm-6 col-xs-12 fL">
      <TexteLexique texte={input.description.childMarkdownRemark.html} />
      
    </section>
  );
};

export default Textes;
