import React from "react";
import Img from "gatsby-image";
import TexteLexique from '../components/ui/TexteLexique'

const Detail = ({ input }) => {
//   console.log(input);

    // useEffect(() => {
    //     window.addEventListener("resize", _format)
    //     _format()

    //     return () => {
    //         window.removeEventListener("resize", _format)
    //     }
    // }, [])

    // const _format = () => {
    //     if (window.innerWidth < 1024 && window.innerWidth >= 768) {
    //         const image = document.querySelectorAll(".detail figure")[0]
    //         // console.log(imagesPortrait)
    //         const {

    //             height
    //         } = image.getBoundingClientRect()
    //         document.querySelector(".detail .texte").style.height = height + "px"
    //     }
    // }
    const _geLegende = (display) => {
        const className = display === "s-only"
        ? "col-sm-6 col-xs-12 "+display
        : " "+display
        return (
            <div className={className}>
                <TexteLexique texte={legende.childMarkdownRemark.html} />
            </div>
        )
    }

    const {
        legende,
        image_s_detail
    } = input
    // const length = image_s_detail.length
    // const mClass = length === 1 ? "col-sm-6" : "col-sm-12"
    // console.log("detail")
    return (
        // <section className={"module detail col-xs-12"}>
        //     <div className="inner">
        //         <div className="hidden-s">
        //         <TexteLexique texte={legende.childMarkdownRemark.html} />
        //         </div>
                
        //         <div className="row">
        //             <div className="col-md-6 col-sm-6 col-xs-12 s-only">
        //                 <div className="inner">
        //                 <TexteLexique texte={legende.childMarkdownRemark.html} />
        //                 </div>
        //             </div>
        //             {image_s_detail.map((image, i) => (
        //                 <div className="col-md-6 col-sm-6 col-xs-12" key={i}>
        //                     <div className="inner">
        //                         <Img
        //                             fluid={image.fluid}
        //                             Tag="figure"
        //                             alt={image.description}
        //                         />
        //                         {/* <div className="index">{"0"+(i+1)}</div> */}
        //                         <div className="index"></div>
        //                     </div>
        //                 </div>
        //             ))}
        //         </div>
        //     </div>
        // </section>
        <>
        
            {_geLegende("s-only")}
            
            {image_s_detail.map((image, i) => {
                const _legende = i === 0
                ? _geLegende("hidden-s")
                : null
                return(
                    <div className={"col-md-6 col-sm-6 col-xs-12 "} key={i}>
                        <div className="inner">
                            {_legende}
                            {/* {_geLegende("hidden-s")} */}
                            <Img
                                fluid={image.fluid}
                                Tag="figure"
                                alt={image.description}
                            />
                            {/* <div className="index">{"0"+(i+1)}</div> */}
                            <div className="index"></div>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default Detail;
