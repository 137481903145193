import React from "react";
import Img from "gatsby-image";

const Images = ({ input }) => {
  // console.log(input);
  const className = input.image.sizes.aspectRatio < 1 ? "is-portrait col-md-4 col-sm-6" : "is-landscape col-md-12"
  return (
    <section className={"module images col-xs-12 "+className} data-ratio={input.image.sizes.aspectRatio}>
      <Img
        fluid={input.image.fluid}
        Tag="figure"
        alt={input.image.description}
      />
    </section>
  );
};

export default Images;
