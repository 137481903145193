import React from "react";

const DonneesTechniques = ({ input }) => {
  // console.log(input)
  const {donnees} = input
  return (
    <section className="module donnees-techniques col-xs-12 col-sm-6 col-md-4">
      <div className="inner">
        <ul className="liste">
            {donnees.map((li,i) => {
                const parts = li.split("$")
                return(
                    <li key={i}>
                        <div className="label crtch">{parts[0]}</div>
                        <div className="value crtch">{parts[1]}</div>
                    </li>
                )
            })}
        </ul>
      </div>
    </section>
  );
};

export default DonneesTechniques;
