
import React, { useEffect } from "react";

const RealisationPrixEtCollaborateurs = ({ input }) => {
  // console.log(input)
    useEffect(() => {
      window.addEventListener("resize", _format)
      _format()
      
      return () => {
        window.removeEventListener("resize", _format)
      }
    }, [])

    const _format = () => {
      if(window.innerWidth < 1024 && window.innerWidth >= 768){
        const imagesPortrait = document.querySelectorAll(".is-portrait")[0]
        console.log(imagesPortrait)
        const { height } = imagesPortrait.getBoundingClientRect()
        document.querySelector(".realisations-prix-collaborateur").style.height = height+"px"
        document.querySelector(".donnees-techniques").style.height = height+"px"
      }else{
        document.querySelector(".realisations-prix-collaborateur").style.height = "auto"
        document.querySelector(".donnees-techniques").style.height = "auto"
      }
    }

    const {
      intitule_prix_recompenses,
      prix_recompenses,
      intitule_collaborateurs,
      collaborateurs
    } = input
    
    return (
      <section className="module realisations-prix-collaborateur col-xs-12 col-sm-6 col-md-4">
        <div className="inner">
          <ul>
            {prix_recompenses &&
            <li>
                <div className="label">{intitule_prix_recompenses}</div>
                <div 
                    className="texte"
                    dangerouslySetInnerHTML={{ __html: prix_recompenses.childMarkdownRemark.html }}></div>
                
            </li>
            }
            {collaborateurs &&
            <li>
                <div className="label">{intitule_collaborateurs}</div>
                <div 
                    className="texte"
                    dangerouslySetInnerHTML={{ __html: collaborateurs.childMarkdownRemark.html }}></div>
            </li>
            }
          </ul>
        </div>
      </section>
  );
};

export default RealisationPrixEtCollaborateurs;
